import { withAuthenticationRequired } from '@auth0/auth0-react'
import { PageProps } from 'gatsby'
import Layout from 'manage-tritag/components/layout'
import CompetitionEditForm from 'manage-tritag/components/pages/competitions/edit-form'

const TeamPage = (props: PageProps) => {
  return (
    <Layout label1="Competitions" label2="Update" url="/competitions">
      <CompetitionEditForm {...props} />
    </Layout>
  )
}

export default withAuthenticationRequired(TeamPage)
