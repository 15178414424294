import { tConv24 } from './time'

export const getFormattedDate = (date: Date) => {
  const year = date.getFullYear()

  let month = (1 + date.getMonth()).toString()
  month = month.length > 1 ? month : `0${month}`

  let day = date.getDate().toString()
  day = day.length > 1 ? day : `0${day}`

  return `${day}/${month}/${year}`
}

export const getFormattedDateYYMMDD = (date: Date) => {
  const year = date.getFullYear()

  let month = (1 + date.getMonth()).toString()
  month = month.length > 1 ? month : `0${month}`

  let day = date.getDate().toString()
  day = day.length > 1 ? day : `0${day}`

  return `${year}-${month}-${day}`
}

export const getFormattedDateYYMMDDAA = (date: Date) => {
  const year = date.getFullYear()

  let month = (1 + date.getMonth()).toString()
  month = month.length > 1 ? month : `0${month}`

  let day = date.getDate().toString()
  day = day.length > 1 ? day : `0${day}`

  const time = date.getHours()
  const minute = date.getMinutes()
  const dateTime = tConv24(
    `${time < 10 ? `0${time}` : time}:${minute < 10 ? `0${minute}` : minute}`,
  )

  return `${year}/${month}/${day} ${dateTime}`
}

export const calculateAge = (birthday: Date) => {
  // birthday is a date
  const ageDifMs = Date.now() - birthday.getTime()
  const ageDate = new Date(ageDifMs) // miliseconds from epoch
  return Math.abs(ageDate.getUTCFullYear() - 1970)
}

export const getMonthName = (i: number) => {
  const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ]

  return monthNames[i]
}

export const getDay = (i: number) => {
  return i < 10 ? `0${i}` : i
}
